import { v4 as uuidv4 } from 'uuid'
import type { HeadersOptions, Middleware } from 'openapi-fetch'
import createClient from 'openapi-fetch'
import { ofetch } from 'ofetch'
import { getSegmentAnonymousId } from '~/lib/tracking/segmentTrack'
import type { paths } from '~/types/api/rental-api-types'
import type {
  GhostConfig,
  GhostPostAndAuthor,
  GhostPosts, GhostRequestParams,
  GhostTagsList,
  GhostTagTransformed, RvDetailsRequired,
} from '~/types'
import { ApiError, type BackendErrorInterface } from '~/types/backend-api-errors'
import type {
  AccountRegisterRequest,
  AccountRegisterResponse,
  AsyncDataRequest, ProfileOtherParameters, ProfileOtherResponse, RvDetailsOfOwnerParameters,
  RvDetailsOfOwnerResponse, RvDetailsParameters, RvUnifiedSearchParameters,
  RvUnifiedSearchResponse, SearchPhotosList, UserReviewParameters, UserReviewResponse,
} from '~/types/rental-api-aliases'
import { COOKIES } from '~/constants/index.js'

const runtimeConfig = useRuntimeConfig()
const ghostConfig = runtimeConfig.public.blog as GhostConfig
ghostConfig.localeTags = runtimeConfig.public.locales

const backendClient = createClient<paths>({
  baseUrl: runtimeConfig.public.apiUrl,
  credentials: 'include',
})

const ghostClient = ofetch.create({ baseURL: ghostConfig.ghostApiUrl })
const { transformGhostPost, transformGhostPage, transformerGhostTags, transformGhostPostWithAuthor } = useGhost()

export default function useApi() {
  const { isMobileOrTablet } = useDevice()
  const { rawToken } = useAuthState()
  const platformCookie = useCookie(COOKIES.platform)
  const cookie = useRequestHeader('cookie')

  const backendClientMiddleware: Middleware = {
    async onRequest({ request }) {
      if (rawToken.value) {
        request.headers.set('Authorization', `Bearer ${rawToken.value}`)
      }

      // User Agent.
      const userAgent = request.headers.get('user-agent')

      if (userAgent) {
        request.headers.set('User-Agent', userAgent)
      }

      // Cloudflare Headers.
      const cfCountry = request.headers.get('cf-ipcountry')
      const cfRegion = request.headers.get('cf-region')
      const cfCity = request.headers.get('cf-ipcity')

      if (cfCountry) {
        request.headers.set('RVZ-ipcountry', cfCountry)
      }

      if (cfRegion) {
        request.headers.set('RVZ-region', cfRegion)
      }

      if (cfCity) {
        request.headers.set('RVZ-ipcity', cfCity)
      }

      request.headers.set('RVZ-Correlation-Id', uuidv4())

      request.headers.set('Platform', platformCookie.value ?? (isMobileOrTablet ? 'mobile_web' : 'web'))

      // Client-only headers.
      if (import.meta.client) {
        request.headers.set('RVZ-Anonymous-Id', getSegmentAnonymousId())
      }

      if (cookie) {
        request.headers.set('cookie', cookie)
      }

      return request
    },
  }

  backendClient.use(backendClientMiddleware)

  return {
    rvApi,
    userApi,
    ghostApi,
  }
}

// default error Handler
export function defaultErrorHandler(error: unknown) {
  // Todo: Fix this type casting if possible
  const err = error as BackendErrorInterface
  throw new ApiError(err.Message, err.status, err.traceId, err.detail)
}

// Transformers
function swapPhotos(rvPhotos: SearchPhotosList | null | undefined) {
  if (!rvPhotos) return rvPhotos
  if (rvPhotos.length < 2) return rvPhotos

  return [rvPhotos[0], rvPhotos[1]] = [rvPhotos[1], rvPhotos[0]]
}
export function rvSearchTransformer(data: RvUnifiedSearchResponse): RvUnifiedSearchResponse {
  if (data.PopularRVs?.ListRVs?.length && data.FeaturedRVs?.ListRVs?.length) {
    const featuredRVsIds = new Set(data.FeaturedRVs.ListRVs.map((rv) => rv.Id))

    data.PopularRVs.ListRVs.forEach((popularRV) => {
      if (featuredRVsIds.has(popularRV.Id) && popularRV.Photos && popularRV.Photos.length > 1) {
        popularRV.Photos = swapPhotos(popularRV.Photos)
      }
    })
  }

  return data
}

// API`S
// Todo: Should they go to another directory ? Where is the best place ?
export const rvApi = {
  async getRvListDetails(key: string, query: RvDetailsParameters): AsyncDataRequest<RvDetailsRequired> {
    return useAsyncData(
      key,
      async () => {
        const { data, error } = await backendClient.GET('/api/rvlistings/details', {
          params: {
            query,
          },
        })

        if (error)
          defaultErrorHandler(error)

        return data
      })
  },

  async getRvUnifiedSearch(key: Ref<string>, query: Ref<RvUnifiedSearchParameters>): AsyncDataRequest<RvUnifiedSearchResponse> {
    return useAsyncData(
      key.value,
      async () => {
        const { data, error } = await backendClient.GET('/api/rvlistings/unified-search', { params: { query: query.value } })
        if (error)
          defaultErrorHandler(error)

        return data
      },
      {
        transform: rvSearchTransformer,
        watch: [query],
      },
    )
  },
}

export const userApi = {

  async getProfileOther(key: Ref<string>, params: ProfileOtherParameters): AsyncDataRequest<ProfileOtherResponse> {
    return useAsyncData(key.value, async () => {
      const { data, error } = await backendClient.GET('/api/user-profiles/profile-other', { params: { query: params } })
      if (error) defaultErrorHandler(error)
      return data
    })
  },

  async getAllUserReviews(key: Ref<string>, params: UserReviewParameters): AsyncDataRequest<UserReviewResponse> {
    return useAsyncData(key.value, async () => {
      const { data, error } = await backendClient.GET('/api/v2/reviews/{userId}', { params: { path: params } })
      if (error) defaultErrorHandler(error)
      return data
    })
  },

  async getRvDetailsOfOwner(key: Ref<string>, params: RvDetailsOfOwnerParameters): AsyncDataRequest<RvDetailsOfOwnerResponse> {
    return useAsyncData(key.value, async () => {
      const { data, error } = await backendClient.GET('/api/rvlistings/rv-details-of-owner/{profileId}', { params: { path: params } })
      if (error) defaultErrorHandler(error)
      return data
    })
  },

  async userSignedUp(key: Ref<string>, request: AccountRegisterRequest, headers: HeadersOptions): AsyncDataRequest<AccountRegisterResponse> {
    return useAsyncData(key.value, async () => {
      const { data, error } = await backendClient.POST('/api/account/register', {
        headers,
        body: request,
      })
      if (error) throw error // defaultErrorHandler(error)
      return data
    })
  },

}

export const ghostApi = {
  async getPage(slug: string) {
    return useFetch(`pages/${slug}`, {
      $fetch: ghostClient,
      params: {
        key: ghostConfig.ghostApiKey,
        limit: 1,
      },
      transform: transformGhostPage,
    })
  },

  async getPosts(slug: string): AsyncDataRequest<GhostPosts> {
    return useFetch(`posts/slug/${slug}`, {
      $fetch: ghostClient,
      params: {
        key: ghostConfig.ghostApiKey,
        include: 'tags,authors',
      },
      transform: transformGhostPost,
    })
  },

  async getPostsFromAuthor(key: Ref<string>, params: Ref<GhostRequestParams>): AsyncDataRequest<GhostPostAndAuthor> {
    return useAsyncData(key.value, () => {
      return ghostClient(`posts/`, {
        params: {
          include: 'tags,authors',
          filter: params.value.filter,
          page: params.value.pageNumber ?? 1,
          limit: params.value.limit ?? 12,
          key: ghostConfig.ghostApiKey,
        },
      })
    },
    {
      watch: [params],
      transform: transformGhostPostWithAuthor,
    })
  },

  async getGhostBlogPosts(key: Ref<string>, params: Ref<GhostRequestParams>): AsyncDataRequest<GhostPosts> {
    return useAsyncData(key.value, () => {
      return ghostClient<GhostPosts>(`posts/`, {
        params: {
          include: 'tags,authors',
          filter: params.value.filter,
          page: params.value.pageNumber ?? 1,
          limit: params.value.limit ?? ghostConfig.pagingSize,
          key: ghostConfig.ghostApiKey,
        },
      })
    }, {
      watch: [params],
    })
  },

  async getGhostTags(): AsyncDataRequest<GhostTagTransformed[] | undefined | null> {
    const i18nTeFunction = useI18n().te
    return useLazyFetch(`tags/`, {
      $fetch: ghostClient,
      transform: (request: GhostTagsList) => {
        return transformerGhostTags(request, ghostConfig.localeTags, i18nTeFunction)
      },
      query: {
        key: ghostConfig.ghostApiKey,
        include: 'count.posts',
        limit: 'all',
        filter: 'visibility:public',
      },
    })
  },
}
