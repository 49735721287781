import type { Composer } from 'vue-i18n'
import type { GhostPages, GhostPostAndAuthor, GhostPosts, GhostTag, GhostTagsList, GhostTagTransformed } from '~/types'

/**
 * A composable that returns a set of Ghost-specific utilities.
 */
export default function useGhost() {
  type I18nTeType = Composer['te']

  /**
   * Appends a Table of Contents to the Ghost Post's HTML.
   */
  function transformGhostPost(data: GhostPosts) {
    if (data.posts?.[0]?.html) {
      data.posts[0].html += '<div id="toc"></div>'
    }

    return data
  }

  /**
   * Transforms the data that comes from Ghost and adds the table of contents
   * to the first returned page.
   */
  const transformGhostPage = (data: GhostPages) => {
    const page = data.pages?.[0]

    if (page) {
      // Set a default feature image if one is not provided.
      page.feature_image = page.feature_image ?? 'https://cdn-s.rvezy.com/cms/ghost-default-image.png'

      // Add the table of contents to the page's HTML.
      page.html = `<h1>${page.title}</h1>${page.html}<div id="toc"></div>`
    }

    return data
  }

  function transformGhostPostWithAuthor(data: GhostPostAndAuthor): GhostPostAndAuthor {
    const author = data.posts?.[0]?.primary_author
    return {
      posts: data.posts,
      meta: data.meta,
      author,
    }
  }

  function filterTagByLanguage(tag: GhostTag, i18nTeFunction: I18nTeType) {
    return i18nTeFunction(`tags.${tag.slug}`) && tag.slug !== 'en' && tag.slug !== 'fr'
  }

  function filterTagByPostCount(tag: GhostTag, localeTags: string[]) {
    return (tag.count?.posts ?? 0) > 0 && !localeTags.includes(tag.slug ?? '')
  }

  function filterTags(tag: GhostTag, localeTags: string[], i18nTeFunction: I18nTeType) {
    return filterTagByLanguage(tag, i18nTeFunction) && filterTagByPostCount(tag, localeTags)
  }

  function sortTags(a: GhostTag, b: GhostTag) {
    return ((a.count?.posts ?? 0) > (b.count?.posts ?? 0) ? -1 : 1)
  }

  function mapTags(tag: GhostTag): GhostTagTransformed {
    return { text: `${tag.name} (${tag.count?.posts})`, value: tag.slug }
  }

  function transformerGhostTags(data: GhostTagsList, localeTags: string[], i18nTeFunction: I18nTeType): GhostTagTransformed[] | undefined {
    return data?.tags?.filter((item) => filterTags(item, localeTags, i18nTeFunction)).sort(sortTags).map(mapTags)
  }

  /**
   * Handles errors that come from the Ghost API.
   */
  function handleGhostError(statusCode: number, type: string, slug: string) {
    throw createError({
      statusCode: statusCode,
      statusMessage: `There was an issue fetching a ${type}: (${slug})`,
    })
  }

  return {
    transformGhostPost,
    transformGhostPage,
    transformerGhostTags,
    transformGhostPostWithAuthor,
    handleGhostError,
  }
}
